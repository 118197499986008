<template>
  <div>
    <BaseLoading
      style="position: fixed; z-index: 3; top: 50%; right: 50%"
      v-if="is_loading"
    ></BaseLoading>
    <div class="order-info text-left">
      <div class="row">
        <div class="col-12 col-sm-7 table-responsive">
          <table
            class="table table-stripped text-left bg-light"
            style="font-size: 13px !important"
          >
            <tr>
              <td>Topic</td>
              <td colspan="2">: <span v-html="order.title"></span></td>
            </tr>

            <tr v-if="order.client">
              <td>Client</td>
              <td class="font-weight-bold">
                <router-link
                  class="text-capitalize"
                  :to="{
                    name: 'ClientProfile',
                    params: { id: order.client.id },
                  }"
                >
                  {{ order.client.names || "" }}
                </router-link>
              </td>
              <td>
                <SilentLogin :site="order.site" :client="order.client" />
              </td>
            </tr>
            <tr>
              <td>Site:</td>
              <td>{{ order.site || "" }}</td>
            </tr>
            <tr>
              <td>Number of Page(s)</td>
              <td>: {{ order.pages || "" }}</td>
              <td></td>
            </tr>
            <tr>
              <td>Number of Slide(s)</td>
              <td>: {{ order.slides || "" }}</td>
              <td></td>
            </tr>
            <tr>
              <td>Number of Chart(s)</td>
              <td>: {{ order.charts || "" }}</td>
              <td></td>
            </tr>

            <tr>
              <td>Spacing</td>
              <td>: {{ order.spacing_text || "" }}</td>
              <td></td>
            </tr>

            <tr>
              <td>Language Style</td>
              <td>: {{ order.language_style_text || "" }}</td>
              <td></td>
            </tr>

            <tr>
              <td>Type of Paper</td>
              <td>: {{ order.document_type_text || "" }}</td>
              <td></td>
            </tr>

            <tr>
              <td>Subject Area</td>
              <td>: {{ order.subject_area_text || "" }}</td>
              <td></td>
            </tr>

            <tr>
              <td>Academic Level</td>
              <td>: {{ order.academic_level_text || "" }}</td>
              <td></td>
            </tr>

            <tr>
              <td>Urgency</td>
              <td>
                <span
                  v-if="order.deadline"
                  :class="order.past_due ? 'text-danger' : 'text-success'"
                  >: {{ order.urgency_text_hrs || "" }}</span
                >
              </td>
              <td></td>
            </tr>
            <tr>
              <td>Client Deadline</td>
              <td
                :class="
                  order.deadline.past_due ? 'text-danger' : 'text-success'
                "
                class="font-weight-bold"
              >
                : {{ order.deadline.date || "" }}
              </td>
              <td
                :class="
                  order.deadline.past_due ? 'text-danger' : 'text-success'
                "
                class="font-weight-bold"
              >
                {{ order.deadline.past_due ? "Elapsed" : "" }}
                {{ order.deadline.ellapsed || "" }}
              </td>
            </tr>

            <tr>
              <td>Editor Deadline</td>
              <td
                :class="
                  order.editor_deadline.past_due
                    ? 'text-danger'
                    : 'text-success'
                "
                class="font-weight-bold"
              >
                : {{ order.editor_deadline.date || "" }}
              </td>

              <td
                :class="
                  order.editor_deadline.past_due
                    ? 'text-danger'
                    : 'text-success'
                "
                class="font-weight-bold"
              >
                {{ order.editor_deadline.past_due ? "Elapsed" : "" }}
                {{ order.editor_deadline.ellapsed || "" }}
              </td>
            </tr>

            <tr>
              <td>Writer Deadline</td>
              <td
                :class="
                  order.writer_deadline.past_due
                    ? 'text-danger'
                    : 'text-success'
                "
                class="font-weight-bold"
              >
                : {{ order.writer_deadline.date || "" }}
              </td>
              <td
                :class="
                  order.writer_deadline.past_due
                    ? 'text-danger'
                    : 'text-success'
                "
                class="font-weight-bold"
              >
                {{ order.writer_deadline.past_due ? "Elapsed" : "" }}
                {{ order.writer_deadline.ellapsed || "" }}
              </td>
            </tr>

            <tr>
              <td>Date Ordered</td>
              <td>: {{ order.date_placed || "" }}</td>
              <td></td>
            </tr>

            <tr>
              <td>State</td>
              <td>: {{ order.state || "" }}</td>
              <td></td>
            </tr>

            <tr>
              <td>Nursing Speciality</td>
              <td>: {{ order.speciality || "" }}</td>
              <td></td>
            </tr>

            <tr>
              <td>Paper Format / Style</td>
              <td>: {{ order.style_text || "" }}</td>
              <td></td>
            </tr>

            <tr>
              <td>Number of sources</td>
              <td>: {{ order.no_sources || "0" }}</td>
              <td></td>
            </tr>
            <tr>
              <td>Writer Level</td>
              <td>: {{ order.writer_level_desc || "0" }}</td>
              <td></td>
            </tr>

            <tr>
              <td>Additional Services</td>
              <td>
                <table class="table table-borderless">
                  <tr
                    v-for="(item, index) in order.additional_services_text"
                    :key="index + 'add'"
                  >
                    <td>{{ item[0] }}</td>
                    <hr />
                  </tr>
                </table>
              </td>
              <td></td>
            </tr>

            <tr>
              <td class="font-weight-bold">Editor Amount</td>
              <td class="font-weight-bold">
                : {{ order.editor_cost ? `$${order.editor_cost}` : "" }}
              </td>
              <td></td>
            </tr>
            <tr>
              <td class="font-weight-bold">Writer Amount</td>
              <td class="font-weight-bold">
                : {{ order.writer_cost ? `$${order.writer_cost}` : "" }}
              </td>
              <td></td>
            </tr>

            <tr
              v-for="(extra, index) in order.extras"
              :key="index + 'oextras'"
              :class="
                extra.paid !== '0'
                  ? 'teal lighten-5 teal--text darken--4'
                  : 'error lighten-5 error--text darken--4'
              "
            >
              <td>{{ extra.name || "" }}</td>
              <td>: ${{ extra.cost || 0 }}</td>
              <td>
                <button
                  v-if="extra.paid !== '0'"
                  class="ml-1 btn btn-sm bg-teal"
                >
                  Paid
                </button>
                <span
                  v-else
                  class="d-flex justify-content-center align-items-center"
                >
                  Unpaid
                  <button
                    @click="deleteExtra(extra.id)"
                    class="mx-1 btn btn-sm btn-danger"
                  >
                    Delete
                    <span class="icon-trash"></span>
                  </button>
                </span>
              </td>
            </tr>
            <tr class="teal lighten-5 teal--text darken--4">
              <td class="font-weight-bold">Extras Total</td>
              <td class="font-weight-bold">: ${{ order.extras_total || 0 }}</td>
              <td></td>
            </tr>
            <tr>
              <td class="font-weight-bold">Total Order Cost</td>
              <td class="font-weight-bold">: ${{ order.cost }}</td>
              <td></td>
            </tr>
            <tr class="teal lighten-5 teal--text darken--4">
              <td class="font-weight-bold">Paid</td>
              <td class="font-weight-bold">: ${{ order.total_paid || 0 }}</td>
              <td></td>
            </tr>
            <tr class="error lighten-5 error--text darken--4">
              <td class="font-weight-bold">Balance</td>
              <td class="font-weight-bold">: ${{ order.balance || 0 }}</td>
              <td></td>
            </tr>
            <tr>
              <td>Paper Details</td>
              <td colspan="2" style="padding: 4px !important">
                <pre style="padding: 4px !important"> 
                  <span v-html="order.description"></span>
                </pre>
              </td>
            </tr>
          </table>
        </div>

        <div class="col-12 col-sm-5">
          <h6 class="font-weight-bold text-uppercase">
            Add Services to Your Paper
          </h6>

          <div class="row">
            <div
              v-for="(item, index) in additionalItems"
              :key="index"
              class="col-6 py-3"
            >
              <p
                class="font-weight-bold text-center is-additional"
                @click="activeComponent = item"
                data-toggle="modal"
                data-target="#exampleModal"
              >
                <img width="62px" :src="item.icon || ''" />
                <br />
                <span class="text-center"> {{ item.title || "" }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>

      <!-- Modal -->
      <BaseModal :title="activeComponent.title">
        <component :is="activeComponent.component"></component>
      </BaseModal>
    </div>
  </div>
</template>


<script>
import pages_png from "../../assets/global_assets/images/ordericons/pages.png";
import slides_png from "../../assets/global_assets/images/ordericons/slides.png";
import charts_png from "../../assets/global_assets/images/ordericons/charts.png";
import deadline_png from "../../assets/global_assets/images/ordericons/deadline.png";
import progressive_png from "../../assets/global_assets/images/ordericons/progressive.png";
import preferences_png from "../../assets/global_assets/images/ordericons/preferences.png";

import AdditionalPages from "../order/modals/AdditionalPages.vue";
import AdditionalServices from "../order/modals/AdditionalServices.vue";
import AdditionalCharts from "../order/modals/AdditionalCharts.vue";
import AdditionalSlides from "../order/modals/AdditionalSlides.vue";
import ShortenDeadline from "../order/modals/ShortenDeadline.vue";
import WriterPreferences from "../order/modals/WriterPreferences.vue";
import { mapActions } from "vuex";
import SilentLogin from "../SilentLogin.vue";
export default {
  props: {
    order: {
      type: Object,
    },
  },
  components: {
    AdditionalPages,
    AdditionalServices,
    AdditionalCharts,
    AdditionalSlides,
    ShortenDeadline,
    WriterPreferences,
    SilentLogin,
  },
  data() {
    return {
      is_loading: false,
      activeComponent: {},
      additionalItems: [
        {
          title: "Request Additional Pages",
          icon: pages_png,
          component: "AdditionalPages",
        },
        {
          title: "Request Powerpoint slides",
          icon: slides_png,
          component: "AdditionalSlides",
        },
        {
          title: "Request Charts",
          icon: charts_png,
          component: "AdditionalCharts",
        },
        {
          title: "Extend Deadline",
          icon: deadline_png,
          component: "ShortenDeadline",
        },
        {
          title: "Update Writer Preferences",
          icon: preferences_png,
          component: "WriterPreferences",
        },
        {
          title: "Additional Services",
          icon: progressive_png,
          component: "AdditionalServices",
        },
      ],
    };
  },

  methods: {
    ...mapActions("order", ["deleteExtras"]),
    deleteExtra(id) {
      this.is_loading = true;
      this.deleteExtras(id)
        .then((res) => {
          console.log(res);
          // this,_getOrder(this.order.id)
          // alert
          this.$notify({
            title: "Success",
            text: "Order extra deleted successiful!",
          });
        })
        .catch((err) => {
          console.log(err);
          // alert
          this.$notify({
            title: "Error",
            text: err.response.data ? err.response.data.Message : "Error",
            style: "danger",
          });
        })
        .finally(() => {
          this.is_loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.table td {
  //   padding: 4px 8px !important;
  max-width: 300px;
}
.is-additional {
  cursor: pointer;
  img {
    margin: 4px;
  }
  &:hover {
    img {
      transform: scale(1.05);
      transition: 0.3s all ease-in-out;
    }
  }
}
</style>

<style lang="scss">
.btn.btn-dark.btn-icon {
  color: #fff !important;
}
</style>