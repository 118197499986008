var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.$store.state.loading)?_c('BaseLoading',{staticStyle:{"min-height":"400px","height":"90vh"}}):_c('div',[_c('DueCounter',{attrs:{"order":_vm.order}}),_c('div',{staticClass:"card is-order-card mt-2 border-0 shadow"},[_c('div',{staticClass:"card-body p-1 bg-white",staticStyle:{"border-radius":"20px"}},[(Object.keys(_vm.order).length > 0)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-sm-6 col-md-8 text-left",attrs:{"sm":"6"}},[_c('h1',{staticClass:"font-weight-bold text-capitalize order-title",staticStyle:{"font-size":"1.6rem"}},[_vm._v(" Order ID # "+_vm._s(_vm.order.id || "")+" ")]),_c('p',[_c('strong',[_vm._v(_vm._s(_vm.order.subject_area_text || ""))]),_vm._v(" | "),_c('strong',[_vm._v(_vm._s(_vm.order.academic_level_text || ""))]),_vm._v(" | "),_c('strong',[_vm._v(_vm._s(_vm.order.pages || "")+" page(s)")])]),_c('span',{staticClass:"badge badge-pill badge-lg bg-primary shadow-sm px-2 text-uppercase"},[_vm._v("Status: "+_vm._s(_vm.order.status_text || ""))])]),_c('div',{staticClass:"col-12 col-sm-6 col-md-4",attrs:{"sm":"6"}},[(
                _vm.order.writer &&
                _vm.order.writer_id &&
                _vm.userType != _vm.userTypes.writer
              )?_c('div',{staticClass:"m-0 text-left"},[_c('h4',{staticClass:"font-weight-bold mb-0 text-left"},[_vm._v(" Writer: "),_c('router-link',{staticClass:"text-capitalize",attrs:{"to":{
                    name: 'WriterProfile',
                    params: { id: _vm.order.writer_id },
                  }}},[_vm._v(" "+_vm._s(_vm.order.writer.writer_name || "")+" ( "+_vm._s(_vm.order.writer_id || "")+" ) ")])],1),_c('p',{staticClass:"is-rating-stars-1 d-flex mb-1"},[_vm._l((5),function(i,index){return _c('span',{key:index + 'rating'},[_c('span',{staticClass:"text-warning icon",class:_vm.order.writer.rating.rate >= index + 1
                        ? 'icon-star-full2'
                        : 'icon-star-empty3'})])}),_c('span',{staticStyle:{"font-size":"16px","font-weight":"bold"}},[_vm._v(_vm._s(_vm.order.writer.rating.rate || "0")+" Stars ")])],2),_c('p',{staticClass:"text-weight-bold d-flex mb-1"},[_vm._v(" "+_vm._s(_vm.order.writer.rating.total_reviews || 0)+" Reviews. "+_vm._s(_vm.order.writer.completed_orders || 0)+" Completed orders ")]),_c('FineComponent',{attrs:{"order_id":_vm.order.id || '-1',"writer_id":_vm.order.writer_id}})],1):_vm._e()])]):_vm._e(),_c('ul',{staticClass:"nav nav-tabs nav-tabs-bottom pl-0"},[_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link active show font-weight-bold text-uppercase",attrs:{"href":"#info","data-toggle":"tab"}},[_vm._v("Order Info")])]),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link font-weight-bold text-uppercase",attrs:{"href":"#messages","data-toggle":"tab"}},[_vm._v("Messages "),(_vm.order.no_messages > 0)?_c('span',{staticClass:"badge badge-danger badge-pill"},[_vm._v(" "+_vm._s(_vm.order.no_messages)+" ")]):_vm._e()])]),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link font-weight-bold text-uppercase",attrs:{"href":"#files","data-toggle":"tab"}},[_vm._v("Files "),(_vm.order.no_files > 0)?_c('span',{staticClass:"badge badge-danger badge-pill"},[_vm._v(" "+_vm._s(_vm.order.no_files)+" ")]):_vm._e()])])]),_c('div',{staticClass:"tab-content"},[_c('div',{staticClass:"tab-pane fade active show",attrs:{"id":"info"}},[(
                _vm.userType == _vm.userTypes.admin || _vm.userType == _vm.userTypes.client
              )?_c('OrderInfo',{attrs:{"order":_vm.order}}):(_vm.userType == _vm.userTypes.editor)?_c('EditorOrderInfo',{attrs:{"order":_vm.order}}):_c('WriterOrderInfo',{attrs:{"order":_vm.order}})],1),_c('div',{staticClass:"tab-pane fade",attrs:{"id":"messages"}},[_c('OrderMessages')],1),_c('div',{staticClass:"tab-pane fade",attrs:{"id":"files"}},[_c('OrderFiles')],1)])])]),_c('div',{staticClass:"card mt-2 border-0 shadow"},[(_vm.order.buttons && _vm.order.buttons.length > 0)?_c('div',{staticClass:"card-body p-1 text-left"},_vm._l((_vm.order.buttons),function(action,index){return _c('span',{key:index + 'oa'},[(!!action)?_c('span',[(action.is_link)?_c('router-link',{staticClass:"btn m-1 text-light",class:`bg-${action.color || 'primary'}`,attrs:{"to":{ name: action.action, params: { id: _vm.order.id } }}},[_vm._v(_vm._s(action.title))]):_c('button',{staticClass:"btn m-1",class:`bg-${action.color || 'primary'}`,attrs:{"data-toggle":"modal","data-target":"#exampleModal1"},on:{"click":function($event){_vm.order_action = action}}},[_vm._v(" "+_vm._s(action.title)+" ")])],1):_vm._e()])}),0):_vm._e()]),_c('UpdateOrderStatus',{attrs:{"action":_vm.order_action}})],1),_c('BaseModal',{attrs:{"title":'Wallet Topup',"modalId":'walletModal'}},[_c('WalletTopup')],1),_c('BaseModal',{attrs:{"title":'Wallet Topup',"modalId":'tipModal'}},[_c('TipWriter')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }